<template>
    <div class="news">
        <h1 class="header-title">Новости сообщества</h1>
        <news-main />
        <!-- <h2 class="header-title">События сообщества</h2> -->
    </div>
</template>


<script>
import NewsMain from '@/components/Newsmodule/NewsMain'

export default {
    components:{
        NewsMain
    },
    created(){
        document.title = 'Новости'
    },
}
</script>
