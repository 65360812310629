<template>
  <div class="news-block">
    <div v-if="db.length" class="news-block__body">
      <div class="news-block__main" v-for="(item, i) of db" :key="i">
        <img :src="item.img" :alt="item.title" class="news-block__img" />
        <div class="news-block__text">
          <h2 class="news-block__title">{{ item.title }}</h2>
          <p class="news-block__description">{{ item.descr }}</p>
          <time>{{ item.createdAt | formatDate }}</time>
        </div>
      </div>
    </div>
    <p v-else>Новостей пока нет</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      db: [],
    };
  },
  filters: {
    formatDate: (d) => {
      const date = new Date(d);
      const year = date.getFullYear();
      const month = date.toLocaleString("ru-RU", { month: "long" });
      const day = date.getDate();
      return `${day} ${month} ${year}`;
    },
  },
  mounted() {
    fetch("http://server.windcrack.beget.tech/api/addNews")
      .then((res) => res.json())
      .then((data) => {
        this.db = data;
      });
  },
};
</script>

<style lang="scss">
.news-block {
  color: #000;

  &__body {
    display: flex;
    flex-direction: column-reverse;
  }

  &__main {
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
    display: flex;
    justify-content: space-between;
    gap: 20px;
    height: 300px;
    margin-bottom: 12px;
    border-bottom: 1px solid #000;
    padding: 12px 0;
    @media screen and (max-width: 600px) {
      height: auto;
      flex-direction: column;
      &:nth-child(odd) {
        flex-direction: column;
      }
    }
  }

  &__img {
    width: 30%;
    object-fit: cover;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &__text {
    width: 75%;
    padding: 15px 0;
    display: flex;
    flex-flow: column;

    h2,
    p {
      margin: 0;
    }
    h2 {
      margin-bottom: 12px;
    }

    time {
      margin-top: auto;
    }
  }
}
</style>